import { Spinner } from "flowbite-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { ArrowRightIcon } from "../../shared-components/utils/icons";
import { Wrapper } from "../../shared-components/Wrapper"
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { useAuth } from "../../contexts/AuthContext";
import { requestCacher, requestPublicGet } from "../../shared-components/services/publicRoutes";
import { CalledType, CallStatusType, hardcodeSupport, translateCallStatus } from "../../shared-components/Wrapper/v3/ButtonHelp";
import { useNotify } from "../../contexts/NotifyContext";
import { ResultAndResponse } from "../../shared-types";
import { hubRoutes } from "../../shared-types/utils/routes";
import { useNavigate } from "react-router-dom";



export const Support = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [calleds, setCalleds] = useState<CalledType[]>([]);
  const [showingCalled, setShowingCalled] = useState<CalledType[]>([]);
  const [filter, setFilter] = useState<{ status?: CallStatusType }>({ });
  
  useEffect(() => { onLoad() },[user, filter])
  useEffect(() => {
    setShowingCalled(!filter.status ? calleds : calleds.filter(
      (call) => call.status === filter.status
    ))
  },[calleds])
  async function onLoad(){
    if(!user || isLoading) return;

    setIsLoading(true);
    await (async () => {
      const variation = filter.status === 'finished' ? 'closed-calls' : 'open-calls';
      const user_id = user.id;
      const client_id = user.current_client;

      const res = await requestCacher({
        key: variation,
        payload: JSON.stringify({ flow_id: hardcodeSupport.flow_id, user_id, client_id }),
        callback: async () => await requestPublicGet({
          flow_id: hardcodeSupport.flow_id,
          variation,
          params: { user_id, client_id }
        })
      }) as ResultAndResponse & { data?: CalledType[] }
      
      if(!res.result){
        toast.error(res.response)
        return;
      }

      if(!res.data) return;
      const { data } = res;
      setCalleds((prevState) => [
        ...prevState.filter(state => !data.some((item) => item._id === state._id)),
        ...data.map<CalledType>((item) => ({
          ...item,
          status: hardcodeSupport.steps["open-request-called"] === item.current_step_id ? 'open':
          hardcodeSupport.steps['called-closed'] === item.current_step_id ? 'finished' : 'in_progress'
        }))
      ]);
    })()
    setIsLoading(false)
  }

  return (
    <Wrapper
      breadcrumbs={[{ name: 'Suporte', href: '#', subtitle: 'Chamados' }]}
      asideActive={'Suporte'}
      module_name="Configurações"
    >
      <div className="mb-6">
        <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 mb-6 px-4 text-primary-700 relative z-10">
          <div className="md:hidden max-sm:w-full">
            <Dropdown
              classNames={{ list: `
                absolute right-0 z-10
                -mt-1 w-56 origin-top-right rounded-md
                bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                ring-1 ring-black ring-opacity-5
                focus:outline-none pb-2
              `, button: 'max-sm:w-full', wrapper: 'relative inline-block text-left group max-sm:w-full' }}
              trigger={
                <div className="
                  my-3 w-40 max-sm:w-full h-10 border border-gray-50/10 
                  flex items-center justify-between p-4 rounded-md bg-gray-100/5 text-sm backdrop-blur-[25px] font-semibold
                "> 
                  {filter.status ? translateCallStatus[filter.status] : 'Chamados'}
                  <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90"/>
                </div>
              }
            >
              {Object.entries({ all: 'Chamados', ...translateCallStatus }).map(([status, name]) => (
                <button
                  className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
                  onClick={() => setFilter((prevState) => ({
                    ...prevState,
                    status: status === 'all' ? undefined : status as CallStatusType
                  }))}
                  key={status}
                >{name}</button>
              ))}
            </Dropdown>
          </div>
          <ul className="hidden md:flex gap-4 text-sm h-16 text-primary-700">
            {Object.entries({ all: 'Chamados', ...translateCallStatus}).map(([status, name]) => (
              <li className={`group ${(status === 'all' && !filter.status) || filter.status === status ? 'active':''}`} key={status}>
                <button
                  type="button"
                  className={`
                    relative h-full px-2 text-center font-semibold
                    
                    after:w-full after:h-1
                    after:-bottom-0.5 after:left-0
                    after:rounded-lg

                    group-[.active]:after:content['']
                    group-[.active]:after:absolute 
                    group-[.active]:after:block 
                    group-[.active]:after:bg-[#6DBFFF] group-[.active]:after:shadow-[0_1px_20px_#6DBFFF]

                    hover:after:content['']
                    hover:after:absolute 
                    hover:after:block 
                    hover:after:bg-[#6DBFFF30]
                    hover:text-primary-500/80
                  `}
                  onClick={() => setFilter((prevState) => ({
                    ...prevState,
                    status: status === 'all' ? undefined : status as CallStatusType
                  }))}
                >{name}</button>
              </li>
            ))}
          </ul>
        </div>
        <div className={`
          h-full min-h-[calc(20rem-3rem)]
          flex flex-col justify-between
        `}>
          <div>
            <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs  text-primary-800 uppercase bg-primary-500/5">
                  <tr>
                    <th className="px-3 py-2 font-bold">Chamados {filter.status ? translateCallStatus[filter.status]:''}</th>
                  </tr>
                </thead>
                <tbody>
                  {showingCalled.map(call => (
                    <tr className="hover:bg-gray-200/80 border-b last:border-none" key={call._id}>
                      <td
                        className="px-3 py-4 cursor-pointer"
                        onClick={() => navigate(hubRoutes.support.details(call._id))}
                      >
                        <div className="flex items-center gap-2 max-w-full">
                          <strong className="text-sm max-w-[calc(100%-2rem)] truncate flex items-center flex-wrap gap-2">
                            {call.process_number} 
                            <span className={`font-semibold block text-[10px] leading-none uppercase py-0.5 px-2 rounded-lg text-gray-100 ${
                              call.status === 'open' ? 'text-gray-500 bg-gray-300' : 
                              call.status === 'in_progress' ? 'text-gray-100 bg-primary-500' :
                              call.status === 'finished' ? 'bg-green-500' : 'bg-gray-100'
                            }`}>
                              {translateCallStatus[call.status]}
                            </span>
                            <span className={`font-semibold block text-[10px] leading-none uppercase py-0.5 px-2 rounded-lg text-gray-100 ${
                              call.type_of_call === 'Melhoria' ? 'text-gray-100 bg-green-600' : 
                              call.type_of_call === 'Dúvida' ? 'text-gray-100 bg-primary-600' :
                              call.type_of_call === 'Problema' ? 'bg-red-500' : 'bg-gray-100'
                            }`}>
                              {call.type_of_call}
                            </span>
                          </strong>
                        </div>
                        <div className="flex flex-col gap-2 mt-0.5">
                          <span className="text-gray-500 text-xs font-normal">
                            {call.description.slice(0, 80) + (call.description.length > 80 ? '...':'')}
                          </span>
                          <em className="text-gray-400 text-xs">Atualizado em {moment(call.updated_at).format('DD/MM/YYYY H:mm')}</em>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {showingCalled.length === 0 && (
                    <tr>
                      <td
                        colSpan={3}
                        className="px-3 py-10 text-center"
                      >
                        {(
                          filter.status === 'open' ? 'Não há chamados em aberto' :
                          filter.status === 'in_progress' ? 'Não há chamados em progresso' :
                          filter.status === 'finished' ? 'Não há chamados finalizados' : '...'
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {isLoading && (
            <div className="absolute inset-0 z-50 bg-gray-50/75 flex items-center justify-center">
              <Spinner
                aria-label="Carregando"
              />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}