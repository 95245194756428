import { Spinner } from "flowbite-react";
import { filter } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { ArrowRightIcon, DownloadAltIcon } from "../../shared-components/utils/icons";
import { Wrapper } from "../../shared-components/Wrapper"
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { useAuth } from "../../contexts/AuthContext";
import { requestCacher, requestPublicGet } from "../../shared-components/services/publicRoutes";
import { CalledType, CallStatusType, hardcodeSupport, translateCallStatus } from "../../shared-components/Wrapper/v3/ButtonHelp";
import { useNotify } from "../../contexts/NotifyContext";
import { ResultAndResponse } from "../../shared-types";
import { hubRoutes } from "../../shared-types/utils/routes";
import { useParams } from "react-router-dom";

export const SupportDetails = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const { _id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [call, setCall] = useState<CalledType>();

  useEffect(() => { onLoad() },[user, _id])
  async function onLoad(){
    if(!_id || !user || isLoading) return;

    setIsLoading(true);
    await (async () => {
      const variation = 'call-details';
      const user_id = user.id;
      const client_id = user.current_client;

      const res = await requestPublicGet({
        flow_id: hardcodeSupport.flow_id,
        variation,
        params: { _id, user_id, client_id }
      }) as ResultAndResponse & { data?: CalledType[] }
      
      if(!res.result){
        toast.error(res.response)
        return;
      }

      if(!res.data || res.data.length < 1){
        toast.error('Chamado não encontrado');
        return;
      }

      const { data } = res;

      const item = data[0];
      setCall({
        ...item,
        status: hardcodeSupport.steps["open-request-called"] === item.current_step_id ? 'open':
        hardcodeSupport.steps['called-closed'] === item.current_step_id ? 'finished' : 'in_progress'
      });
    })()
    setIsLoading(false)
  }

  return (
    <Wrapper
      breadcrumbs={[
        { name: 'Suporte', href: hubRoutes.support.home() },
        { name: 'Chamado', href: '#', subtitle: call?.process_number }
      ]}
      asideActive={'Suporte'}
      module_name="Configurações"
    >
      {call && (
        <div className="mb-6">
          <div className="flex flex-col group show-collapsed-table">
            <button type="button" className="
              block bg-gray-300 hover:bg-gray-300/80 py-0.5 px-5 rounded text-sm text-gray-700 text-center relative
              focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
            "><strong>Resumo</strong></button>
            <table className="
              w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-1 
              hidden group-[.show-collapsed-table]:table
            ">
              <tbody>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Status:</th>
                  <td
                    className="px-2 py-4 text-right min-w-[8rem]"
                    style={{ wordBreak: 'break-word' }}
                  >{translateCallStatus[call.status]}</td>
                </tr>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Tipo:</th>
                  <td
                    className="px-2 py-4 text-right min-w-[8rem]"
                    style={{ wordBreak: 'break-word' }}
                  >{call.type_of_call}</td>
                </tr>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Descrição:</th>
                  <td
                    className="px-2 py-4 text-right min-w-[8rem]"
                    style={{ wordBreak: 'break-word' }}
                  >{call.description}</td>
                </tr>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <td className="px-2 py-4" colSpan={2}>
                    <p className="font-medium text-gray-800 dark:text-white mb-2">Anexos:</p>
                    {call.attachments && (
                      <div className="grid xs:grid-cols-2 gap-2 grid-flow-row">
                        {call.attachments.map(file => {
                          if(!file.name || !file.url){
                            console.log(`[view-file-multiple: attachments > ${file.id}]: Formato de campo de multiplos arquivos, inválido`)
                            return <></>;
                          }
                          return (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={file.url}
                              className="
                                col-span-2
                              text-gray-600 outline-none
                                px-4 py-2.5 border rounded-lg 
                                flex items-center justify-center text-center gap-1
                                focus:outline-none focus:ring-2 focus:ring-offset-2
                                focus:ring-gray-200 hover:bg-gray-100 hover:border-2
                              "
                            >
                              <DownloadAltIcon w={20} h={20}/>
                              <span className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-24px)]">{file.name}</span>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="
            flex flex-col group show-collapsed-table 
            my-4
          ">
            <button type="button" className="
              block bg-gray-300 hover:bg-gray-300/80 py-0.5 px-5 rounded text-sm text-gray-700 text-center relative
              focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
            "><strong>Identificação</strong></button>
            <table className="
              w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-1 
              hidden group-[.show-collapsed-table]:table
            ">
              <tbody>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row" className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Nome do Usuário:</th>
                  <td className="px-2 py-4 text-right min-w-[8rem]" style={{ wordBreak: 'break-word' }}>{call.user.name}</td>
                </tr>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row" className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Email do Usuário:</th>
                  <td className="px-2 py-4 text-right min-w-[8rem]" style={{ wordBreak: 'break-word' }}>{call.user.email}</td>
                </tr>
                <tr className="bg-gray-50/50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 ">
                  <th
                    scope="row" className="px-2 py-4 min-w-[8rem] font-medium text-gray-800 dark:text-white"
                    style={{ wordBreak: 'break-word' }}
                  >Nome da Empresa:</th>
                  <td className="px-2 py-4 text-right min-w-[8rem]" style={{ wordBreak: 'break-word' }}>{call.client.name}</td>
                </tr>
              </tbody>
            </table>
          </div>       
        </div>
      )}
    </Wrapper>
  )
}